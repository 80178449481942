// General imports
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import '../navbar/Menu.css';

const Menu = (hamburgerstatus, sethamburgerstatus) => {
    return (
        <>
            {/* navigation menu */}
            <div className="burgerMenuDiv">
                <div className="burgerLinks">
                    <Link to="/" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Home</Link>
                    <Link to="/discs" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Discs</Link>
                    <Link to="/bags" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Bags</Link>
                    <Link to="/gear" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Gear</Link>
                    <Link to="/contact" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Contact</Link>
                    <Link to="/about" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>About</Link>
                </div>
            </div>
        </>
    )
}

export default Menu;