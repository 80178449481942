import React from "react";
import "../bags/BagsMobile.css";

// image imports
import ShuttleM from "../img/axiomShuttle.png";
import BindleM from "../img/dynamicBindle.png";
import CadetM from "../img/dynamicCadet.png";
import CombatM from "../img/dynamicCombat.png";
import RogerM from "../img/dynamicRoger.png";
import SniperM from "../img/dynamicSniper.png";
import StarterM from "../img/dynamicStarter.png";
import SwiftM from "../img/latitudeSwift.png";

const BagsMobile = () => {

    return (

        <>

            <div id="bagsMobileContainer">
                <h1>Bags</h1>
                <div className="bagContainer">
                    <img src={StarterM} alt="Discmania Starter Bag" className="bagImageMobile" />
                    <p>Discmania Starter Bag</p>
                </div>
                <div className="bagContainer">
                    <img src={CadetM} alt="Dynamic Discs Cadet Bag" className="bagImageMobile" />
                    <p>Dynamic Discs Cadet</p>
                </div>
                <div className="bagContainer">
                    <img src={SwiftM} alt="Latitude 64 Swift Bag" className="bagImageMobile" />
                    <p>Latitude 64 Swift</p>
                </div>
                <div className="bagContainer">
                    <img src={BindleM} alt="HSCo Bindle Disc Golf Bag" className="bagImageMobile" />
                    <p>HSCo Bindle</p>
                </div>
                <div className="bagContainer">
                    <img src={ShuttleM} alt="Axiom Shuttle Bag, Watermelon Edition" className="bagImageMobile" />
                    <p>Axiom Shuttle (Watermelon)</p>
                </div>
                <div className="bagContainer">
                    <img src={SniperM} alt="Dynamic Discs Combat Sniper" className="bagImageMobile" />
                    <p>Dynamic Discs Combat Sniper</p>
                </div>
                <div className="bagContainer">
                    <img src={CombatM} alt="Dynamic Discs Combat Commander Bag" className="bagImageMobile" />
                    <p>Dynamic Discs Combat Commander</p>
                </div>
                <div className="bagContainer">
                    <img src={RogerM} alt="Dynamic Discs Paratrooper, Jolly Roger Edition" className="bagImageMobile" />
                    <p>Dynamic Discs Paratrooper</p>
                </div>
            </div>

        </>

    )
}

export default BagsMobile;