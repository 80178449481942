import React from "react";
import "../bags/BagsTablet.css";

// image imports
import Shuttle from "../img/axiomShuttle.png";
import Bindle from "../img/dynamicBindle.png";
import Cadet from "../img/dynamicCadet.png";
import Combat from "../img/dynamicCombat.png";
import Roger from "../img/dynamicRoger.png";
import Sniper from "../img/dynamicSniper.png";
import Starter from "../img/dynamicStarter.png";
import Swift from "../img/latitudeSwift.png";

const BagsTablet = () => {

    return (

        <>

            <div id="bagsDivTablet">
                <h1>Bags</h1>
                <div id="bagsContainerTablet">
                    <div className="bagsRowTablet">
                        <div className="bagsColTablet">
                            <img src={Starter} alt="Discmania Starter Bag" className="bagImageTablet" />
                            <p>Discmania Starter Bag</p>
                        </div>
                        <div className="bagsColTablet">
                            <img src={Cadet} alt="Dynamic Discs Cadet Bag" className="bagImageTablet" />
                            <p>Dynamic Discs Cadet</p>
                        </div>
                        <div className="bagsColTablet">
                            <img src={Swift} alt="Latitude 64 Swift Bag" className="bagImageTablet" />
                            <p>Latitude 64 Swift</p>
                        </div>
                    </div>
                    <div className="bagsRowTablet">
                        <div className="bagsColTablet">
                            <img src={Bindle} alt="HSCo Bindle Disc Golf Bag" className="bagImageTablet" />
                            <p>HSCo Bindle</p>
                        </div>
                        <div className="bagsColTablet">
                            <img src={Shuttle} alt="Axiom Shuttle Bag, Watermelon Edition" className="bagImageTablet" />
                            <p>Axiom Shuttle (Watermelon)</p>
                        </div>
                        <div className="bagsColTablet">
                            <img src={Sniper} alt="Dynamic Discs Combat Sniper" className="bagImageTablet" />
                            <p>Dynamic Discs Combat Sniper</p>
                        </div>
                    </div>
                    <div className="bagsRowTablet lastRow">
                        <div className="bagsColTablet">
                            <img src={Combat} alt="Dynamic Discs Combat Commander Bag" className="bagImageTablet" />
                            <div className="longName">
                                <p>Dynamic Discs Combat Commander</p>
                            </div>
                        </div>
                        <div className="bagsColTablet">
                            <img src={Roger} alt="Dynamic Discs Paratrooper, Jolly Roger Edition" className="bagImageTablet" />
                            <p>Dynamic Discs Paratrooper</p>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default BagsTablet;