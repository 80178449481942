import React from "react";
import "../contact/Contact.css";

// image imports
import Logo from "../img/DGlogo.png";

const Contact = () => {

    return (

        <>
        
            <div id="contactDiv">
                <h1>Contact Us</h1>
                <div id="formContainer">
                    <div id="leftCol">
                        <img src={Logo} alt="DG Discshop logo" id="dglogo"/>
                    </div>
                    <div id="rightCol">
                        <form action="#">
                            <div className="formSection">
                                <label htmlFor="name" id="nameLabel">Name:</label>
                                <input type="text" name="name" id="name"/>
                            </div>
                            <div className="formSection">
                                <label htmlFor="email" id="emailLabel">Email:</label>
                                <input type="email" name="email" id="email"/>
                            </div>
                            <div className="formSection">
                                <label htmlFor="message" id="messageLabel">Message:</label>
                                <textarea name="message" id="message" cols="30" rows="8"></textarea>
                                <textarea name="messageTablet" id="messageTablet" cols="15" rows="8"></textarea>
                            </div>
                            <button id="contactButton">Submit</button>
                        </form>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Contact;