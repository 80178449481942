import React from "react";
import "../gear/GearTablet.css";

// image imports
import Flashlight from "../img/gearFlashlight.png";
import Basket from "../img/gearBasket.png";
import Chair from "../img/gearChair.png";
import Flubby from "../img/gearFlubby.png";
import Frisbee from "../img/gearFrisbeeTwo.png";
import Game from "../img/gearGame.png";
import Giftcard from "../img/gearGiftcard.png";
import Glow from "../img/gearGlowOne.png";
import MiniOne from "../img/gearMiniOne.png";
import MiniTwo from "../img/gearMiniTwo.png";
import Waffle from "../img/gearSack.png";
import Water from "../img/gearWater.png";

const GearTablet = () => {

    return (

        <>

            <div id="gearTablet">
                <h1>Gear</h1>
                <div id="gearTabletContainer">
                    <div className="gearTabletRow">
                        <div className="gearTabletCol">
                            <img src={Waffle} alt="waffle sacks" className="gearTabletImage" />
                            <p>Waffle Sacks</p>
                        </div>
                        <div className="gearTabletCol">
                            <img src={MiniOne} alt="Discmania Mini Marker" className="gearTabletImage" />
                            <p>Discmania Mini Marker</p>
                        </div>
                        <div className="gearTabletCol">
                            <img src={MiniTwo} alt="Eclipse Nano Mini Marker" className="gearTabletImage" />
                            <p>Eclipse Nano Mini Marker</p>
                        </div>
                    </div>
                    <div className="gearTabletRow">
                        <div className="gearTabletCol">
                            <img src={Flubby} alt="Flubby Wubby" className="gearTabletImage" />
                            <p>Flubby Wubby</p>
                        </div>
                        <div className="gearTabletCol">
                            <img src={Basket} alt="Dynamic Discs Recruit Lite Basket" className="gearTabletImage" />
                            <p>Dynamic Discs Recruit Lite Basket</p>
                        </div>
                        <div className="gearTabletCol">
                            <img src={Glow} alt="Dynamic Discs LED Night Glider" className="gearTabletImage" />
                            <p>Dynamic Discs LED Night Glider</p>
                        </div>
                    </div>
                    <div className="gearTabletRow">
                        <div className="gearTabletCol">
                            <img src={Chair} alt="Dynamic Discs Disc Golf Mesh Tripod Stool Chair" className="gearTabletImage" />
                            <p>Dynamic Discs Tripod Stool Chair</p>
                        </div>
                        <div className="gearTabletCol">
                            <img src={Flashlight} alt="UV Flashlight" className="gearTabletImage" />
                            <p>MVP UV Flashlight</p>
                        </div>
                        <div className="gearTabletCol">
                            <img src={Frisbee} alt="Dynamic Discs Glow Beach Glider" className="gearTabletImage" />
                            <p>Dynamic Discs Glow Beach Glider</p>
                        </div>
                    </div>
                    <div className="gearTabletRow">
                        <div className="gearTabletCol">
                            <img src={Giftcard} alt="DG Disc shop gift card" className="gearTabletImage" />
                            <p>DG Discshop giftcard</p>
                        </div>
                        <div className="gearTabletCol">
                            <img src={Water} alt="Dynamic Discs Water Bottle" className="gearTabletImage" />
                            <p>Dynamic Discs Water Bottle</p>
                        </div>
                        <div className="gearTabletCol">
                            <img src={Game} alt="Dynamic Duel" className="gearTabletImage" />
                            <p>Dynamic Duel</p>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default GearTablet;