import React from 'react';
import '../Footer/Footer.css';

const Footer = () => {

    return (

        <>
        
            <div id="footerContainer">
                
            </div>
        
        </>

    )
}

export default Footer;