import React from "react";
import "../gear/GearMobile.css";

// image imports
import FlashlightM from "../img/gearFlashlight.png";
import BasketM from "../img/gearBasket.png";
import ChairM from "../img/gearChair.png";
import FlubbyM from "../img/gearFlubby.png";
import FrisbeeM from "../img/gearFrisbeeTwo.png";
import GameM from "../img/gearGame.png";
import GiftcardM from "../img/gearGiftcard.png";
import GlowM from "../img/gearGlowOne.png";
import MiniOneM from "../img/gearMiniOne.png";
import MiniTwoM from "../img/gearMiniTwo.png";
import WaffleM from "../img/gearSack.png";
import WaterM from "../img/gearWater.png";

const GearMobile = () => {

    return (

        <>

            <div id="gearMobileContainer">
                <h1>Gear</h1>
                <div className="gearContainer">
                    <img src={WaffleM} alt="waffle sacks" className="gearImageMobile" />
                    <p>Waffle Sacks</p>
                </div>
                <div className="gearContainer">
                    <img src={MiniOneM} alt="Discmania Mini Marker" className="gearImageMobile" />
                    <p>Discmania Mini Marker</p>
                </div>
                <div className="gearContainer">
                    <img src={MiniTwoM} alt="Eclipse Nano Mini Marker" className="gearImageMobile" />
                    <p>Eclipse Nano Mini Marker</p>
                </div>
                <div className="gearContainer">
                    <img src={FlubbyM} alt="Flubby Wubby" className="gearImageMobile" />
                    <p>Flubby Wubby</p>
                </div>
                <div className="gearContainer">
                    <img src={BasketM} alt="Dynamic Discs Recruit Lite Basket" className="gearImageMobile" />
                    <p>Dynamic Discs Recruit Lite Basket</p>
                </div>
                <div className="gearContainer">
                    <img src={GlowM} alt="Dynamic Discs LED Night Glider" className="gearImageMobile" />
                    <p>Dynamic Discs LED Night Glider</p>
                </div>
                <div className="gearContainer">
                    <img src={ChairM} alt="Dynamic Discs Disc Golf Mesh Tripod Stool Chair" className="gearImageMobile" />
                    <p>Dynamic Discs Tripod Stool Chair</p>
                </div>
                <div className="gearContainer">
                    <img src={FlashlightM} alt="UV Flashlight" className="gearImageMobile" />
                    <p>MVP UV Flashlight</p>
                </div>
                <div className="gearContainer">
                    <img src={FrisbeeM} alt="Dynamic Discs Glow Beach Glider" className="gearImageMobile" />
                    <p>Dynamic Discs Glow Beach Glider</p>
                </div>
                <div className="gearContainer">
                    <img src={GiftcardM} alt="DG Disc shop gift card" className="gearImageMobile" />
                    <p>DG Discshop giftcard</p>
                </div>
                <div className="gearContainer">
                    <img src={WaterM} alt="Dynamic Discs Water Bottle" className="gearImageMobile" />
                    <p>Dynamic Discs Water Bottle</p>
                </div>
                <div className="gearContainer">
                    <img src={GameM} alt="Dynamic Duel" className="gearImageMobile" />
                    <p>Dynamic Duel</p>
                </div>
            </div>

        </>

    )
}

export default GearMobile;