import React from "react";
import "../Homepage/Month.css";

// image imports
import Putter from '../img/P2putter.png';

const Month = () => {

    return (

        <>

            <div id="monthContainer">
                <div className="monthRow monthHeader">
                    <h1>Disc of the Month</h1>
                </div>
                <div className="monthRow bottomRow">
                    <div className="monthCol leftCol">
                        <img src={Putter} alt="P2 putter" id="putterImage" />
                    </div>
                    <div className="monthCol rightCol">
                        <div id="dotmTextTop">
                            <p>Like us on Facebook for a chance to win free discs!</p>
                        </div>
                        <div id="dotmTextBottom">
                            <p>* We select a disc of the month that followers have a chance to win throughout the year. Each month's winner is selected on the last day of the month.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Month;