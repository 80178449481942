import React from "react";
import "../about/About.css";

// image imports
import AboutPic from "../img/aboutPic.png";

const About = () => {

    return (

        <>
        
            <div id="aboutDiv">
                <h1>About Us</h1>
                <img src={AboutPic} alt="about us" id="aboutPic"/>
                <p>DG Discshop is a family-owned and operated business based in the western suburbs of Chicago. Our owner, Jody, is a passionate disc golfer who has dedicated his business to providing the highest quality products to other disc golf enthusiasts. We carry a wide range of discs from top manufacturers, ensuring that our customers have access to the best options on the market. At DG Discshop, we're dedicated to promoting the sport of disc golf and helping players of all levels improve their game.</p>
            </div>

        </>

    )
}

export default About;