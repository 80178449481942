// General imports
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import '../navbar/Navbar.css';

// Image imports
import Logo from '../img/DGlogo.png';
import Cart from '../img/cartIcon.png';

// Component imports
import Menu from "../navbar/Menu";

const Navbar = (props) => {

    // State variables (for hamburger menu)
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    return (

        <>

            {/* Hamburger Menu - Only visible on mobile */}

            <div className="HamburgerMenuDiv" style={{ display: hamburgerOpen ? 'inline' : 'none' }}>
                <Menu hamburgerstatus={hamburgerOpen} sethamburgerstatus={setHamburgerOpen} />
            </div>

            {/* Navbar */}

            <nav>
                <div id="logoCartDiv">
                    <div id='logoDiv'>
                        <img src={Logo} alt="DG Logo" id='DGLogo' />
                        <p>DG Disc Shop</p>
                    </div>
                    <div id='cartDiv'>
                        <p>cart (0)</p>
                        <img src={Cart} alt="cart icon" id='cartIcon' />
                    </div>
                </div>
                <div className="navbar">
                    <div className="navLinks">
                        <ul className="links">
                            <li><Link to="/" className="navigationLink linkBreak">Home</Link></li>
                            <li><Link to="/discs" className="navigationLink linkBreak">Discs</Link></li>
                            <li><Link to="/bags" className="navigationLink linkBreak">Bags</Link></li>
                            <li><Link to="/gear" className='navigationLink linkBreak'>Gear</Link></li>
                            <li><Link to="/contact" className='navigationLink linkBreak'>Contact</Link></li>
                            <li><Link to="/about" className='navigationLink'>About</Link></li>
                        </ul>
                    </div>
                    <div className="hamburgerContainer">
                        <Hamburger color="#ffffff" duration={0.7} rounded onToggle={toggled => {
                            if (toggled) {
                                setHamburgerOpen(!hamburgerOpen)
                            } else {
                                setHamburgerOpen(!hamburgerOpen)
                            }
                        }} />
                    </div>
                </div>
            </nav>

        </>

    )
}

export default Navbar;