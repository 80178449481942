import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../Homepage/ManuMobile.css';

// image imports
import DiscmaniaMobile from '../img/DiscmaniaLogo.png';
import DiscraftMobile from '../img/DiscraftLogo.png';
import DynamicMobile from '../img/DynamicLogo.png';
import InnovaMobile from '../img/InnovaLogo.png';
import WestsideMobile from '../img/WestsideLogo.png';

const ManuMobile = () => {

    return (

        <>

            <div id="manufacturersContainerMobile">
                <Carousel interval={1500}>
                    <Carousel.Item>
                        <img src={DiscmaniaMobile} alt="Discmania" className='manMobileImg'/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={DiscraftMobile} alt="Discraft" className='manMobileImg discraftMobileC'/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={InnovaMobile} alt="Innova" className='manMobileImg innovaMobileC'/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={WestsideMobile} alt="Westside Discs" className='manMobileImg'/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={DynamicMobile} alt="Dynamic Discs" className='manMobileImg'/>
                    </Carousel.Item>
                </Carousel>
            </div>

        </>

    )
}

export default ManuMobile;