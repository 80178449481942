import React from "react";
import '../Homepage/HomeDisplay.css';
import Carousel from 'react-bootstrap/Carousel';

// image imports
import Wafflesack from '../img/Wafflesack.png';
import ShopPic from '../img/dgShop.jpg';
import DiscGod from "../img/discGod.png";
import DiscGodNorse from "../img/discGodNorse.png";

const HomeDisplay = () => {

    return (
        <>
            <Carousel id="homepageCarousel">
                <Carousel.Item>
                    <div className="carouselContainer">
                        <div className="godsOverlay" />
                        <img src={DiscGod} alt="Olympic Disc God" id="discGod" />
                        <img src={DiscGodNorse} alt="norse Disc God" id="discGodTwo"/>
                        <div id="DiscGodsText">
                            <p>Unleash Your Inner Disc God</p>
                            <p>With DG Disc Shop</p>
                            {/* <p>DG Disc Shop</p> */}
                            {/* <p>Home of the Disc Gods</p> */}
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="carouselContainer">
                        <div className="godsOverlay overlayTwo" />
                        <img
                            className="wafflesack"
                            src={Wafflesack}
                            alt="Second slide"
                        />
                    </div>
                    <Carousel.Caption className="carouselCaption">
                        <h3>Waffle Sacks</h3>
                        <p>Homemade by DG Discshop. Each wafflesack comes with a lifetime warranty!</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="carouselContainer">
                        <div className="godsOverlay overlayTwo" />
                        <img src={ShopPic} alt="dg mobile shop" className="shopPic" />
                    </div>
                    <Carousel.Caption className="carouselCaption">
                        <h3>Pop-up Shop</h3>
                        <p>
                            Follow us on social media to see where we set up shop next!
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default HomeDisplay;