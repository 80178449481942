import React from 'react';
import '../Homepage/Manufacturers.css';

// image imports
import Discmania from '../img/DiscmaniaLogo.png';
import Discraft from '../img/DiscraftLogo.png';
import Dynamic from '../img/DynamicLogo.png';
import Innova from '../img/InnovaLogo.png';
import Westside from '../img/WestsideLogo.png';

// component imports
import ManuMobile from './ManuMobile';

const Manufacturers = () => {

    return (

        <>
        
            <div id="manufacturersContainer">
                <img src={Discmania} alt="Discmania logo" className='manufacturerLogo'/>
                <img src={Discraft} alt="Discraft logo" className='manufacturerLogo'/>
                <img src={Innova} alt="Innova logo" className='manufacturerLogo'/>
                <img src={Westside} alt="Westside Discs logo" className='manufacturerLogo'/>
                <img src={Dynamic} alt="Dynamic Discs logo" className='manufacturerLogo'/>
            </div>

            <div id="manuMobileContainer">
                <ManuMobile/>
            </div>

        </>

    )
}

export default Manufacturers;