import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

// Component imports
import Home from './components/Homepage/Home';
import About from './components/about/About';
import Bags from './components/bags/Bags';
import Contact from './components/contact/Contact';
import Discs from './components/discs/Discs';
import Gear from './components/gear/Gear';
import Navbar from './components/navbar/Navbar';
import Footer from './components/Footer/Footer';

function App() {

  return (
    <BrowserRouter>
      <Navbar />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About/>}/>
          <Route path="/bags" element={<Bags/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/discs" element={<Discs/>}/>
          <Route path="/gear" element={<Gear/>}/>
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
