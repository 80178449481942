import React from "react";
import "../gear/Gear.css";

// image imports
import Flashlight from "../img/gearFlashlight.png";
import Basket from "../img/gearBasket.png";
import Chair from "../img/gearChair.png";
import Flubby from "../img/gearFlubby.png";
import Frisbee from "../img/gearFrisbeeTwo.png";
import Game from "../img/gearGame.png";
import Giftcard from "../img/gearGiftcard.png";
import Glow from "../img/gearGlowOne.png";
import MiniOne from "../img/gearMiniOne.png";
import MiniTwo from "../img/gearMiniTwo.png";
import Waffle from "../img/gearSack.png";
import Water from "../img/gearWater.png";

// component imports
import GearTablet from "../gear/GearTablet";
import GearMobile from "../gear/GearMobile";

const Gear = () => {

    return (

        <>

            <div id="gearDiv">
                <h1>Gear</h1>
                <div id="gearContainer">
                    <div className="gearRow">
                        <div className="gearCol">
                            <img src={Waffle} alt="waffle sacks" className="gearImage"/>
                            <p>Waffle Sacks</p>
                        </div>
                        <div className="gearCol">
                            <img src={MiniOne} alt="Discmania Mini Marker" className="gearImage"/>
                            <p>Discmania Mini Marker</p>
                        </div>
                        <div className="gearCol">
                            <img src={MiniTwo} alt="Eclipse Nano Mini Marker" className="gearImage"/>
                            <p>Eclipse Nano Mini Marker</p>
                        </div>
                        <div className="gearCol">
                            <img src={Giftcard} alt="DG Disc shop gift card" className="gearImage"/>
                            <p>DG Discshop giftcard</p>
                        </div>
                    </div>
                    <div className="gearRow">
                        <div className="gearCol">
                            <img src={Flubby} alt="Flubby Wubby" className="gearImage"/>
                            <p>Flubby Wubby</p>
                        </div>
                        <div className="gearCol">
                            <img src={Basket} alt="Dynamic Discs Recruit Lite Basket" className="gearImage"/>
                            <p>Dynamic Discs Recruit Lite Basket</p>
                        </div>
                        <div className="gearCol">
                            <img src={Glow} alt="Dynamic Discs LED Night Glider" className="gearImage"/>
                            <p>Dynamic Discs LED Night Glider</p>
                        </div>
                        <div className="gearCol">
                            <img src={Water} alt="Dynamic Discs Water Bottle" className="gearImage"/>
                            <p>Dynamic Discs Water Bottle</p>
                        </div>
                    </div>
                    <div className="gearRow">
                        <div className="gearCol">
                            <img src={Chair} alt="Dynamic Discs Disc Golf Mesh Tripod Stool Chair" className="gearImage"/>
                            <p>Dynamic Discs Disc Tripod Stool Chair</p>
                        </div>
                        <div className="gearCol">
                            <img src={Flashlight} alt="UV Flashlight" className="gearImage"/>
                            <p>MVP UV Flashlight</p>
                        </div>
                        <div className="gearCol">
                            <img src={Frisbee} alt="Dynamic Discs Glow Beach Glider" className="gearImage"/>
                            <p>Dynamic Discs Glow Beach Glider</p>
                        </div>
                        <div className="gearCol">
                            <img src={Game} alt="Dynamic Duel" className="gearImage"/>
                            <p>Dynamic Duel</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="gearDivTablet">
                <GearTablet/>
            </div>

            <div id="gearDivMobile">
                <GearMobile/>
            </div>

        </>

    )
}

export default Gear;