import React from "react";
import "../bags/Bags.css";

// image imports
import Shuttle from "../img/axiomShuttle.png";
import Bindle from "../img/dynamicBindle.png";
import Cadet from "../img/dynamicCadet.png";
import Combat from "../img/dynamicCombat.png";
import Roger from "../img/dynamicRoger.png";
import Sniper from "../img/dynamicSniper.png";
import Starter from "../img/dynamicStarter.png";
import Swift from "../img/latitudeSwift.png";

// component imports
import BagsTablet from "../bags/BagsTablet";
import BagsMobile from "../bags/BagsMobile";

const Bags = () => {

    return (

        <>

            <div id="bagsDiv">
                <h1>Bags</h1>
                <div id="bagsContainer">
                    <div className="bagsRow">
                        <div className="bagsCol">
                            <img src={Starter} alt="Discmania Starter Bag" className="bagImage" />
                            <p>Discmania Starter Bag</p>
                        </div>
                        <div className="bagsCol">
                            <img src={Cadet} alt="Dynamic Discs Cadet Bag" className="bagImage" />
                            <p>Dynamic Discs Cadet</p>
                        </div>
                        <div className="bagsCol">
                            <img src={Swift} alt="Latitude 64 Swift Bag" className="bagImage" />
                            <p>Latitude 64 Swift</p>
                        </div>
                        <div className="bagsCol">
                            <img src={Combat} alt="Dynamic Discs Combat Commander Bag" className="bagImage" />
                            <div className="longName">
                                <p>Dynamic Discs Combat Commander</p>
                            </div>
                        </div>
                    </div>
                    <div className="bagsRow">
                        <div className="bagsCol">
                            <img src={Bindle} alt="HSCo Bindle Disc Golf Bag" className="bagImage" />
                            <p>HSCo Bindle</p>
                        </div>
                        <div className="bagsCol">
                            <img src={Shuttle} alt="Axiom Shuttle Bag, Watermelon Edition" className="bagImage" />
                            <p>Axiom Shuttle (Watermelon)</p>
                        </div>
                        <div className="bagsCol">
                            <img src={Sniper} alt="Dynamic Discs Combat Sniper" className="bagImage" />
                            <p>Dynamic Discs Combat Sniper</p>
                        </div>
                        <div className="bagsCol">
                            <img src={Roger} alt="Dynamic Discs Paratrooper, Jolly Roger Edition" className="bagImage" />
                            <p>Dynamic Discs Paratrooper</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="bagsTablet">
                <BagsTablet/>
            </div>

            <div id="bagsMobile">
                <BagsMobile/>
            </div>

        </>

    )
}

export default Bags;