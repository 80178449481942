import React from "react";
import "../discs/Discs.css";

// image imports
import Putter from "../img/P2putter.png";
import Midrange from "../img/warship.png";
import Fairway from "../img/sapphire.png";
import Driver from "../img/photon.png";
import Discmania from "../img/DiscmaniaLogo.png";
import MVP from "../img/mvpLogo.png";
import Dynamic from "../img/DynamicLogo.png";
import Latitude from "../img/latitudeLogo.png";
import Westside from "../img/WestsideLogo.png";
import Fourth from "../img/fourthCircleLogo.png";
import Axiom from "../img/axiomLogo.png";
import Streamline from "../img/streamlineLogo.png";
import Daredevil from "../img/daredevilLogo.png";
import Gateway from "../img/gatewayLogo.png";

const Discs = () => {

    return (

        <>

            <div id="discsDiv">
                <h1>Discs</h1>
                <h3>Search by disc type:</h3>
                <br />
                <div className="discCategoryDiv">
                    <div className="typeContainer">
                        <img src={Putter} alt="putter disc" className="discImage" />
                        <p>Putt & Approach</p>
                    </div>
                    <div className="typeContainer">
                        <img src={Midrange} alt="midrange disc" className="discImage" />
                        <p>Midrange</p>
                    </div>
                    <div className="typeContainer">
                        <img src={Fairway} alt="fairway driver disc" className="discImage" />
                        <p>Fairway Drivers</p>
                    </div>
                    <div className="typeContainer">
                        <img src={Driver} alt="driver disc" className="discImage" />
                        <p>Distance Drivers</p>
                    </div>
                </div>
                <h3>Search by manufacturer:</h3>
                <br />
                <div className="discCategoryDiv manufacturerDiv">
                    <div className="manufacturerContainer">
                        <img src={Discmania} alt="Discmania Discs Logo" className="manuLogo"/>
                        <p>Discmania</p>
                    </div>
                    <div className="manufacturerContainer">
                        <img src={MVP} alt="MVP Discs Logo" className="manuLogo"/>
                        <p>MVP</p>
                    </div>
                    <div className="manufacturerContainer">
                        <img src={Dynamic} alt="Dynamic Discs Logo" className="manuLogo"/>
                        <p>Dynamic Discs</p>
                    </div>
                    <div className="manufacturerContainer">
                        <img src={Latitude} alt="Latitude 64 Logo" className="manuLogo smallLogo"/>
                        <p>Latitude 64</p>
                    </div>
                    <div className="manufacturerContainer">
                        <img src={Westside} alt="Westside Discs Logo" className="manuLogo"/>
                        <p>Westside Discs</p>
                    </div>
                    <div className="manufacturerContainer">
                        <img src={Fourth} alt="Fourth Circle Discs Logo" className="manuLogo"/>
                        <p>Fourth Circle Discs</p>
                    </div>
                    <div className="manufacturerContainer">
                        <img src={Axiom} alt="Axiom Discs Logo" className="manuLogo"/>
                        <p>Axiom Discs</p>
                    </div>
                    <div className="manufacturerContainer">
                        <img src={Streamline} alt="Streamline Discs Logo" className="manuLogo"/>
                        <p>Streamline Discs</p>
                    </div>
                    <div className="manufacturerContainer">
                        <img src={Daredevil} alt="Daredevil Discs Logo" className="manuLogo smallLogo"/>
                        <p>Daredevil Discs</p>
                    </div>
                    <div className="manufacturerContainer">
                        <img src={Gateway} alt="Gateway Discs Logo" className="manuLogo"/>
                        <p>Gateway</p>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Discs;