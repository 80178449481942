import React from "react";
import "../Homepage/Home.css";

// Component imports
import Display from '../Homepage/HomeDisplay';
import Month from '../Homepage/Month';
import Product from '../Homepage/Products';
import Manufacturer from '../Homepage/Manufacturers';

const Home = () => {
    
    return (
        
        <>
            <div id="homepageDiv">

                {/* Homepage Carousel */}
                <Display />

                {/* Disc of the month */}
                <Month/>

                {/* Products */}
                <Product/>

                {/* Manufacturers */}
                <Manufacturer/>

            </div>
        </>

    )
}

export default Home;