import React from 'react';
import '../Homepage/Products.css';

// image imports
import Flashlight from '../img/Flashlight.png';
import Bag from '../img/bagSelection.png';
import Putter from '../img/P2putter.png';

const Products = () => {

    return (

        <>
        
            <div id="productsContainer">
                <h1 id='productsHeader'>Products</h1>
                <div className="productsSection">
                    <img src={Putter} alt="P2 putter" id='productPutter'/>
                    <p className='productLabel'>Discs</p>
                </div>
                <div className="productsSection">
                    <img src={Bag} alt="disc golf bag" id='productBag'/>
                    <p className='productLabel'>Bags</p>
                </div>
                <div className="productsSection">
                    <img src={Flashlight} alt="flashlight" id='productFlashlight'/>
                    <p className='productLabel'>Gear</p>
                </div>
            </div>
        
        </>


    )
}

export default Products;